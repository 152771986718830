import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import tonyStill from "../../images/Blind-angels-tony-video.png";
import zakiaStill from "../../images/Blind-angels-zakia-video.png";
import joseStill from "../../images/Blind-angels-jose-joaquin-video.png";
import dazonStill from "../../images/Blind-angels-dazon-woman-holding-hands.png";
import kaylaStill from "../../images/Blind-angels-kayla-video.png";

const getVideoStill = (page) => {
  switch (page) {
    case "tony":
      return tonyStill;
    case "champion-dafina":
      return tonyStill;
    case "zakia":
      return zakiaStill;
    case "jose":
      return joseStill;
    case "dazon":
      return dazonStill;
    case "kayla":
      return kaylaStill;
  }
};

const getButtonCopy = (page) => {
  switch (page) {
    case "tony":
      return "Tony's ";
    case "zakia":
      return "Zakia's ";
    case "jose":
      return "Their ";
    case "dazon":
      return "Dázon's ";
    case "kayla":
      return "Kayla's ";
  }
};

export default class VideoCTA extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Container xs={10}>
         
  
        <Row className="viral-suppression">
          <Col xs={11} lg={10} className="mx-auto text-left pt-5">
            <p>
              <span className="hang">*</span>Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>13</sup>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
