import * as React from "react";
import { Component } from "react";
import Modal from "react-bootstrap/Modal";
import CloseButton from "../../images/close-button.png";

class HTML5Video extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <>
        <Modal
          className="cont-video"
          centered
          show={this.props.show}
          onHide={this.props.handleClose}
        >
          <img
            className="close-button"
            onClick={this.props.handleClose}
            src={CloseButton}
          />
          <Modal.Body>
            <video
              className="video-player"
              poster={this.props.thumbnail}
              autoPlay
              controls
              controlsList="nodownload"
              disablePictureInPicture
              data-video-track-label={this.props.videoTrackLabel}
              id="1111"
            >
              <source src={this.props.url} type="video/mp4" />
                {this.props.closedCaptions &&
                  <track label="English" kind="subtitles" srcLang="en" src={this.props.closedCaptions}></track>
                }
            </video>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default HTML5Video;
