import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

const BlockQuote = (props) => {
  console.log('props', props)
  return (
    <Container className="px-0">
      <Row xs={12} className="justify-content-center">
        <Col xs={12} md={9} className="m-auto">
          <div 
            className={ props.author ? 
              "bq-container" : "bq-container bq-container-no-author"
            }
          >
            <p className="bq-quote">{ props.quote }</p>

            {
              props.author && !props.bottomContent ? (
                <p className="bq-author">
                  <div>{props.author}</div>
                </p>
              ):null
            }

            {
              props.author && props.bottomContent ? (
                <p className="bq-authorTitle">
                  <div className="authorContainer">{props.author}</div>
                  <div className="titleContainer">{props.bottomContent}</div>
                </p>
              ):null
            }
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default BlockQuote;