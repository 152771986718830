import React, { Component } from "react";
import Parser from "html-react-parser";
import HTML5Video from "../global/video.js";
import { Container, Row, Col } from "react-bootstrap";
import References from "../global/references";
import BlockQuote from "../articles/Blockquote";
import VideoCTA from "./VideoCTA";
import BackToTop from "../global/BackToTop";

// Importing video files
import tony from "../../images/Blind-angels-tony-video-thumbnail.png";
import jose from "../../images/Blind-angels-jose-joaquin-video-thumbnail.png";
import zakia from "../../images/Blind-angels-zakia-video-thumbnail.png";
import dazon from "../../images/Blind-angels-dazon-video-thumbnail.png";
import kayla from "../../images/Blind-angels-kayla-video-thumbnail.png";

// Assigns a component based on the data passed
const assignComponent = (pageProp, copy, key) => {
  switch (copy.type) {
    case "subheading2":
      return <div key={key}>{Parser(copy.content)}</div>;
    case "text":
      return <p key={key}>{Parser(copy.content)}</p>;
    case "signatureName":
      return (
        <div className="signature" key={key}>
          {Parser(copy.content)}
        </div>
      );
    case "signaturedesc":
      return <div key={key}>{Parser(copy.content)}</div>;
    case "championsofchangelandinglink":
      return (
        <div className="championsofchangelandinglink" key={key}>
          {Parser(copy.content)}
        </div>
      );
    //   if (copy.content.championsofchangelandinglink) {
    //     return (
    //     <p key={key}
    //     className="championsofchangelandinglink"
    //     >
    //     link={copy.content.link}
    //
    //   );
    // }

    case "copy-block":
      return copy.content;

    case "blockquote":
      return (
        <BlockQuote
          key={key}
          page={pageProp}
          quote={Parser(copy.content)}
          author={copy.author}
          bottomContent={copy.bottomContent}
        />
      );

    case "image":
      if (copy.content.mobileSrc) {
        return (
          <div
            key={key}
            className="article-image-container col-xs-11 col-lg-10 mx-auto d-flex flex-column"
          >
            <img
              className="article-image-image d-none d-md-block"
              src={copy.content.src}
              alt={copy.content.alt}
            />
            <img
              className="article-image-image d-block d-md-none"
              src={copy.content.mobileSrc}
              alt={copy.content.alt}
            />
            {/** 
            <span className="article-image-caption mt-2">
              {copy.content.caption}
            </span> */}
          </div>
        );
      }
    case "disclaimer":
      return (
        <p class="champion-disclaimer">
          *This organization is a Gilead grant recipient.
        </p>
      );
      return (
        <div
          key={key}
          className="article-image-container col-xs-11 col-lg-10 mx-auto my-5 d-flex flex-column"
        >
          <img
            className="article-image-image"
            src={copy.content.src}
            alt={copy.content.alt}
          />
          <span className="article-image-caption mt-3">
            {copy.content.caption}
          </span>
        </div>
      );
  }
};

export default class ArticleLayout extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      VideoSrc: undefined,
      VideoThumb: undefined,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClick = (video) => {
    let VideoSrc;
    let VideoThumb;
    let VideoTrackLabel;


    switch (video) {

      default:
        break;
    }
    this.setState({
      show: true,
      VideoThumb: VideoThumb,
      VideoSrc: VideoSrc,
      VideoTrackLabel: VideoTrackLabel,
    });
  };

  render() {
    return (
      <div className={"article article-" + this.props.page}>
        <HTML5Video
          handleClose={this.handleClose}
          show={this.state.show}
          thumbnail={this.state.VideoThumb}
          url={this.state.VideoSrc}
          videoTrackLabel={this.state.VideoTrackLabel}
        />
        {this.props.page != "24th-ias-conference-2022" && (
          <Row
            xs={11}
            lg={10}
            className={"hero " + this.props.page + " px-0"}
            role="img"
            aria-label={this.props.ariaLabel}
          ></Row>
        )}

        <Container className="">
          <Row>
            <Col xs={10} className="mx-auto px-0">
              <div
                className={
                  "hero-callout w-100 hero-callout-" +
                  this.props.page
                }
              >

                <h1>
                    <span className="d-block">{this.props.subheading}</span>
                    {this.props.heading}
              </h1>

              </div>
              {
                  this.props.publishedDate && 
                  <p className="published-cta text-uppercase">Originally published {this.props.publishedDate} <span>| {this.props.minuteRead}-Minute Read</span></p>
               }

            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={10} className="mx-auto px-0">
              {this.props.copyList.map((copy, i) => {
                let el = assignComponent(this.props.page, copy, i);
                return el;
              })}
            </Col>
          </Row>
        </Container>

        {this.props.page != "marlene-mcneese" &&
          this.props.page != "tiffany-west" && (
            <VideoCTA
              page={this.props.page}
              videoStillAlt={this.props.videoStillAlt}
              openVideo={this.handleClick}
            />
          )}
        {this.props.page === "zakia" && (
          <Container>
            <Row>
              <Col xs={10} className="mx-auto">
                <p className="asterisk-note">
                  *Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>13</sup>
                </p>
              </Col>
            </Row>
          </Container>
        )}

        {this.props.page === "dazon" && (
          <Container>
            <Row>
              <Col xs={10} className="mx-auto mt-0">
                <p className="asterisk-note">
                  *This organization is a Gilead grant recipient.
                </p>
              </Col>
            </Row>
          </Container>
        )}

        <BackToTop />
      </div>
    );
  }
}
